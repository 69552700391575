<template>
	<div
		id="app"
	>
		<div
			class="page-container">
			<router-view/>
		</div>
	</div>
</template>
<script>
export default {
 
    data() {
        return {
        };
    },
    computed: {
    },
    watch: {
      
    },
    mounted() {
    },
    methods: {

    },
};
</script>
<style lang="less">
/* 确保 Toast 提示层显示在弹框之上 */
.van-toast {
  z-index: 10000 !important;
}
</style>

